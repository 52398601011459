@import 'variable';
.navbar-toggler:focus {

     box-shadow: unset;
}
nav {
        padding-top: 0!important;
        padding-bottom: 0!important;
        font-weight: bold;
            
        a.nav-link.last{
            border-right:none;
        }

    a.nav-link {
        text-decoration: none;
        color: $blue-color!important;
        padding: 37px 30px!important;
        border-right: 1px solid #a9d7ff;
        text-transform: uppercase;

    }

    a.nav-link:hover {
        color: $green-color!important;
    }

    a.nav-link.active {
        color:$green-color!important;
    }

    .navbar-brand {
        padding: 0px;
    }
    .branding {
        height: auto;
        margin-top: -32px;
        max-height: 130px;
        width: auto;
    }

    .dropdown-menu {
        width: 230px;
        margin-top:0!important;
        border-radius: 0;
        border:none;    padding: 0px;



    }
            
    .dropdown-menu li {
        padding: 0px;
    }



    .dropdown-menu li:hover {

            border: 0px !important;
        }



         .dropdown-menu li a:hover {
            color: #fff!important;   
                     background-color:$blue-color!important;

        }

        .dropdown-menu li a.active {
            color: #fff!important;   
                     background-color:$blue-color!important;

        }

    .dropdown-menu a {
        text-decoration: none;
        padding: 0.5rem 0;
        margin: 0;
        color: $blue-color!important;

        padding: 10px 15px;
        display: block;  
    }


}
@media (max-width: 766px){

    nav {
  
      .branding {
        margin-top: -12px!important;
        max-height: 70px!important;
        width: auto!important;
        margin: 5px 0px!important;
      }
      a.nav-link {
        padding: 5px 0px !important;   
         border-right: 0px solid #a9d7ff;

      }
      .dropdown-menu {
        width: 100%!important;
      }
  
  
    }
  
    .w-100 {
        width: 270% !important;
        margin-left: -90%;
    }
    // .carousel-item {
    //     width: 80%;
    //     margin-right: -100%;
    // }

    
  }







  @media only screen and (min-width: 0px) and (max-width: 567px)  {

        // .navbar-light .navbar-toggler {
        //     margin-top: 20px;
        //     float: right;
        // }
        .navbar-collapse {
            flex-basis: 100%;
            top: 80px;
            flex-grow: 1;
            position: absolute;
            align-items: center;
            z-index: 11111111111;
            margin-left: -20px;
            width: 100%;
            text-align: left;
        }
        .navbar-nav {
            display: flex;
            flex-direction: column;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            width: 240px;
            float: right;
            padding: 20px;
            background: #fff;
        }


  }


