.navbox img, svg {
    width: 100%;
    max-width: 231px;
} 


@media only screen and (min-width: 0px) and (max-width: 567px)  {

    .navigationboxes {

        .col-sm-3 {
            width:50%;
        }

        h4 {
            font-family: "Great Vibes", "Open Sans";
            color: #0065bd;
            font-size: 34px;
        }
    }

}