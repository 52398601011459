

@font-face {
    font-family: 'Open Sans';
    src: url(../fonts/OpenSans-Regular.ttf);
}
@font-face {
    font-family: 'Open Sans Light';
    src: url(../fonts/OpenSans-Light.ttf);
}
@font-face {
    font-family: 'Great Vibes';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/GreatVibes-Regular.ttf) format('truetype');
}

$blue-color: #0065bd;
$light-green-color:#99D786;
$green-color: #00bd3f;
$dark-green-color:#257206;
$white-color: #fff;
$gray-color:#444444;
$lightgray-color:#777777;
$black-color:#000;





$font1:"Open Sans";
$font2:"Open Sans Light";
$font3:"Great Vibes";

$fontsize0: 12px;
$fontsize1: 13px;
$fontsize2: 14px;
$fontsize3: 15px;
$fontsize4: 16px;
$fontsize5: 17px;
$fontsize6: 24px;
$fontsize7: 27px;
$fontsize8: 43px;
$fontsize9: 67px;

