@import 'variable';


  
p {
  line-height: 26px;
}
b, strong {
  color:$blue-color;
}
  body {
    font-family: $font1!important;
    color: #787d85;

}
h1 {
  font-family: $font3, $font1;
  color:$blue-color;

}
h3{    
   font-family: $font2;
  color: $gray-color;
  line-height: 38px;
}


h4 {
  font-family: $font3, $font1;
  color:$blue-color;
  font-size: $fontsize8;

}

h4 span {
  display:block;
  color:$green-color;
  font-size: $fontsize6;
}


h5 {
  font-family: $font3, $font1;
  color:$green-color;
  font-size: $fontsize7;

}
.fa-ul {
  margin-left: 0 !important;
  
}


@media (max-width: 1050px){

  .container, .container-md, .container-sm {
      max-width: 100%;
  }
}