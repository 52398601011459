@import 'variable';

footer {
    color:$lightgray-color;
    font-size: $fontsize2;
    border-top: 1px solid #f5f5f5;
    background: #fcfcfc;
    margin-top: 40px;

    // display: table;
    // height: 400px;
    // overflow: hidden;
    // width: 100%;

    p {
        margin-bottom:0px;
    }
    .row  {
        display: inline-table;
          width: 100%;
          padding: 10px 0px;
      
    }
    .container {
        width: 100%;
    
        .col-sm-6 {

        display: table-cell;
        vertical-align: middle;
        }
    }

    a {
        color:$black-color;
        text-decoration: none;
    }
    img {
        margin-right: 10px;
    }
       
    .col-lg-6, .col-md-6 {
        display: inherit;
    }

}

@media only screen and (min-width: 0px) and (max-width:599px)  {

    
    footer {
        .text-end {
            text-align: center!important;
        }
        // .col-lg-6, .col-md-6 {

        // text-align: center!important;
        // }
         .row {
            height: auto;
            width: 100%;
            padding: 20px 0px;
        }
        img {
        display: block;
        margin: auto;
        text-align: center;

        }
        .container {
            text-align: center;
        }

        

    }

}
@media only screen and (min-width: 600px) and (max-width: 1050px)  {
    footer {
        .text-end {
            text-align: center!important;
        }
        .col-lg-6, .col-md-6 {
            width: 100%;

         text-align: center!important;
         }
         .row {
            height: auto;
            width: 100%;
            padding: 20px 0px;
        }
        img {
        display: block;
        margin: auto;
        text-align: center;

        }
        .container {
            text-align: center;
        }

        img {
            text-align: center;
            height: auto;
            display: block;
            margin: auto;
        
        }

    }

    
}

@media only screen and (min-width: 1051px) and (max-width: 1200px)  {
    footer img {
        height: auto;
        width:150px;
    
    }

}