@import 'variable';

.accordion-button {
    background-color:$blue-color;
        color: $white-color;
}

.accordion-button:not(.collapsed) {
    color:$white-color;
    background-color:$dark-green-color;

}
.accordion-body {
    padding: 1rem 1.25rem;
    background:$light-green-color;
    color: $black-color;
}
