@import 'variable';
#page_title {

    background-image: url(../images/leaf.jpg);

    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    display: table;
    height: 400px;
    overflow: hidden;
    width: 100%;
    text-align: center;

    .container {
        display: table-cell;
        vertical-align: middle;
    }

    h1 {
        font-family: $font3, cursive;
        font-size:$fontsize9;
        text-shadow: 1px 1px 1px #fff;
    }

}



@media (max-width: 766px){
    #page_title {

        height: 230px;
    
        h1 {
            font-size: 47px;
        }
    }
}

@media (max-width: 1023px){
    #page_title {

        height: 270px;
    
        h1 {
            font-size: 50px;
        }
    }
}