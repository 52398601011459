@import 'variable';
#quote {
    background-image: url(../images/form.jpg);
    padding-top: 250px;
    background-position: top center;
    padding-bottom: 50px;
    border-bottom: 10px solid $green-color;
 h1 {
    color:$white-color;
 }

.form-label {
    margin-bottom: .5rem;
    color: #fff;
    display: block;
    float: left;
    width: 20%
}
.form-select, .form-control {
    display: block;
    float: right;
    width: 80%
}
.btn-primary {
    color: #fff;
    background-color: $green-color;
    border-color: $green-color;
    margin-left: 20%;

}


}

#msg {
    color: $green-color;
    padding: 10px;
    border-radius: 5px;
    margin-top:15px;
}

@media (max-width: 576px){
    
#quote {
        .form-select, .form-control, .form-label {
            width: 100%;
        }
        .btn-primary {
            margin-left: 0;
        }
    }
}
