@import 'variable';
.topbar {
    background-color: $blue-color;
    height: 30px;
    color:#fff;
    font-size: $fontsize2;
    line-height: 28px;
    color: #35daff;

    .svg-inline--fa.fa-w-16 {
        color: #fff;
        margin: 0px 4px;
    }
}
